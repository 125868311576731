<template>
    <div>
        <DxValidationGroup ref="formValidation">
            <div class="flex-clear-icon">
                <DxButton
                    type="normal"
                    styling-mode="contained"
                    icon="mdi mdi-filter-variant-remove"
                    @click="xoaLoc"
                />
            </div>
            <div class="pb-3">
                <span class="font-12">Trạng thái (*)</span>
                <DxSelectBox
                    v-model="model.TrangThai"
                    :data-source="DanhSachTrangThai"
                    value-expr="id"
                    display-expr="text"
                    validationMessageMode="always"
                    styling-mode="underlined"
                    :onValueChanged="onChangeTrangThai"
                >
                    <DxValidator>
                        <DxRequiredRule message="Trạng thái không được bỏ trống!" />
                    </DxValidator>
                </DxSelectBox>
            </div>

            <div class="pb-3">
                <span class="font-12">Từ ngày</span>
                <DxDateBox
                    v-model="model.TuNgay"
                    displayFormat="dd/MM/yyyy"
                    :openOnFieldClick="true"
                    :use-mask-behavior="true"
                    dropDownButtonTemplate="customIcon"
                    validationMessageMode="always"
                    styling-mode="underlined"
                    pickerType="rollers"
                    :min="MinDate"
                    type="date"
                    :onValueChanged="onChangeNgayXuatBenKH"
                >
                    <template #customIcon="{}">
                        <DxButton icon="mdi mdi-calendar" />
                    </template>
                    <DxValidator>
                        <DxRequiredRule message="Từ ngày không được bỏ trống!" />
                        <DxRangeRule
                            :max="model.TuNgay"
                            message="Từ ngày phải nhỏ hơn đến ngày!"
                        />
                        <DxRangeRule
                            :min="MinDate"
                            :message="$i18n.t('DefaultString.MinDate')"
                        />
                        <!-- <DxRangeRule
                            :min="MinDate"
                            message="Ký từ ngày không được nhỏ hơn ngày hiện tại!"
                        /> -->
                        <!-- <DxCustomRule
                            :validationCallback="validationTuNgayDenNgay"
                            message="Khoảng ngày chọn phải nhỏ hơn 31!"
                        /> -->
                    </DxValidator>
                </DxDateBox>
            </div>
            <div class="pb-3">
                <span class="font-12">Đến ngày</span>
                <DxDateBox
                    v-model="model.DenNgay"
                    displayFormat="dd/MM/yyyy"
                    :openOnFieldClick="true"
                    :use-mask-behavior="true"
                    dropDownButtonTemplate="customIcon"
                    validationMessageMode="always"
                    styling-mode="underlined"
                    pickerType="rollers"
                    type="date"
                    :onValueChanged="onChangeNgayXuatBenKH"
                >
                    <template #customIcon="{}">
                        <DxButton icon="mdi mdi-calendar" />
                    </template>
                    <DxValidator>
                        <DxRequiredRule message="Đến ngày không được bỏ trống!" />
                        <DxRangeRule
                            :max="MaxDate"
                            :message="$i18n.t('DefaultString.MaxDate')"
                        />
                        <DxRangeRule
                            :min="model.TuNgay"
                            message="Đến ngày phải lớn hơn từ ngày!"
                        />
                        <DxCustomRule
                            :validationCallback="validationTuNgayDenNgay"
                            message="Khoảng ngày chọn phải nhỏ hơn 31!"
                        />
                    </DxValidator>
                </DxDateBox>
            </div>
            <div class="pb-3">
                <span class="font-12">Tuyến vận chuyển</span>
                <DxSelectBox
                    ref="TuyenVanChuyen"
                    v-model="model.TuyenVanChuyen"
                    :dataSource="data.TuyenVanChuyen"
                    :display-expr="
                        (x) =>
                            x
                                ? x.IdDnvtTuyen != '00000000-0000-0000-0000-000000000000'
                                    ? `${x.TenTuyen} (${x.MaTuyen})`
                                    : x.TenTuyen
                                : ''
                    "
                    value-expr="IdDnvtTuyen"
                    styling-mode="underlined"
                    no-data-text="Không có dữ liệu!"
                    :dropDownOptions="{
                        onContentReady: onContentReady,
                        width: '100%',
                    }"
                    :searchEnabled="true"
                    :showClearButton="true"
                    item-template="item"
                >
                    <template #item="{ data }">
                        <div
                            style="
                                white-space: break-spaces;
                                border-bottom: 1px solid #dadce0;
                            "
                        >
                            {{
                                data
                                    ? data.IdDnvtTuyen !=
                                      "00000000-0000-0000-0000-000000000000"
                                        ? `${data.TenTuyen} (${data.MaTuyen})`
                                        : data.TenTuyen
                                    : ""
                            }}
                        </div>
                    </template>
                </DxSelectBox>
            </div>
        </DxValidationGroup>
    </div>
</template>
<script>
import {
    DxDateBox,
    DxSelectBox,
    DxValidationGroup,
    DxValidator,
    DxButton,
    DxTextBox,
} from "devextreme-vue";
import { DxRangeRule } from "devextreme-vue/validator";
import { DxDropDownBox } from "devextreme-vue/drop-down-box";
import { DxList } from "devextreme-vue/list";
import {
    DxRequiredRule,
    DxStringLengthRule,
    DxCustomRule,
} from "devextreme-vue/data-grid";

export default {
    layout: "application",
    components: {
        DxButton,
        DxValidationGroup,
        DxDateBox,
        DxValidator,
        DxRequiredRule,
        DxSelectBox,
        DxDropDownBox,
        DxList,
        DxTextBox,
        DxStringLengthRule,
        DxCustomRule,
        DxRangeRule,
    },
    props: {
        filter: {
            type: Object,
            default: () => ({
                TrangThai: 1,
                TuNgay: this.$Helper.getStartDay(new Date()),
                DenNgay: this.$Helper.getEndDay(new Date()),
                TuyenVanChuyen: "00000000-0000-0000-0000-000000000000",
            }),
        },
    },
    data() {
        return {
            model: {
                TuNgay: this.$Helper.getStartDay(new Date()),
                TuyenVanChuyen: "00000000-0000-0000-0000-000000000000",
                DenNgay: this.$Helper.getEndDay(new Date()),
                TrangThai: 1,
            },
            data: {
                TuyenVanChuyen: [],
                GioXuatBenKH: [],
            },
            DanhSachTrangThai: [
                {
                    id: this.$t("TrangThaiLenh_LocTuyen.DuKien"),
                    text: "Danh sách chuyến đi dự kiến",
                },
                {
                    id: this.$t("TrangThaiLenh_LocTuyen.DaCap"),
                    text: " Danh sách lệnh Đã cấp cho lái xe ",
                },
                {
                    id: this.$t("TrangThaiLenh_LocTuyen.DangThucHien"),
                    text: "Danh sách lệnh Đang thực hiện",
                },
                {
                    id: this.$t("TrangThaiLenh_LocTuyen.HoanThanh"),
                    text: "Danh sách lệnh Đã hoàn thành",
                },
                {
                    id: this.$t("TrangThaiLenh_LocTuyen.KhongHoanThanh"),
                    text: "Danh sách lệnh Không hoàn thành",
                },
            ],

            CheckLayDsTuyen: false,
        };
    },
    computed: {
        GioXuatBenKH_selected: {
            get() {
                // return this.data.GioXuatBenKH.filter(x =>);
            },
        },
        Get_viewPage() {
            return this.$store.state.DVVTLenh.viewPage;
        },
        MaxDate() {
            return new Date(this.$t("DefaultNumBer.MaxDate"));
        },
        MinDate() {
            return new Date(this.$t("DefaultNumBer.MinDate"));
        },
    },
    methods: {
        selectedItemKeys(e) {},
        onContentReady(e) {
            var html = e.component.content();
            html.style.cssText = "padding: 0px !important";
        },
        validationTuNgayDenNgay() {
            let TuNgay = new Date(this.$Helper.getStartDay(this.model.TuNgay)).getTime();
            let DenNgay = new Date(this.$Helper.getEndDay(this.model.DenNgay)).getTime();

            let minusResult = (DenNgay - TuNgay) / (1000 * 60 * 60 * 24);
            if (minusResult > 31) return false;
            return true;
        },
        checkYear() {
            let startYear = new Date(this.model.TuNgay).getFullYear();
            let endYear = new Date(this.model.DenNgay).getFullYear();
            return startYear == endYear;
        },
        checkDay() {
            let today = this.model.DenNgay;
            let cDay = this.model.TuNgay;
            let c = today.getTime() - cDay.getTime();
            return c / 1000 / 60 / 60 / 24 < 31;
        },

        async LayDanhSachLuongTuyen() {
            try {
                this.data.TuyenVanChuyen = await this.$store.dispatch(
                    "DVVTLenh/Get_DanhSachLuongTuyen",
                );
                this.data.TuyenVanChuyen.unshift({
                    TenTuyen: "Tất cả",
                    IdDnvtTuyen: "00000000-0000-0000-0000-000000000000",
                });
            } catch (ex) {
                console.log("🚀 ex", ex);
            }
        },
        xoaLoc() {
            this.model.TuNgay = this.$Helper.getStartDay(new Date());
            this.model.DenNgay = this.$Helper.getEndDay(new Date());
            this.model.TuyenVanChuyen = "00000000-0000-0000-0000-000000000000";
        },
    },
    created() {
        this.model.TrangThai = this.DanhSachTrangThai.find(
            (e) => e.id == this.Get_viewPage,
        ).id;
        this.model.TuNgay = new Date(this.filter.TuNgay);
        this.model.DenNgay = new Date(this.filter.DenNgay);
        this.model.TuyenVanChuyen =
            this.filter.TuyenVanChuyen || "00000000-0000-0000-0000-000000000000";
        this.LayDanhSachLuongTuyen();
    },
    mounted() {
        // setTimeout(() => {
        //     if (this.filter.NgayXuatBenKH) {
        //         this.model.TrangThai = this.filter.TrangThai;
        //         this.model.TimKiem = this.filter.TimKiem;
        //         this.model.NgayXuatBenKH = this.filter.NgayXuatBenKH;
        //         this.model.TuyenVanChuyen = this.filter.TuyenVanChuyen;
        //         this.changeTuyenVanChuyen();
        //     }
        //     this.CheckLayDsTuyen = true;
        //     this.LayDSTuyenVanChuyenTheoNgay();
        // }, 100);
    },
};
</script>
<style scoped></style>
<style lang="scss" scoped>
.flex-clear-icon {
    display: flex;
    justify-content: right;
    position: fixed;
    top: 16px;
    right: 16px;
}
</style>
